import * as React from 'react';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';

const NotFoundPage: React.FC = () => (
  <Layout>
    <Seo title="404: Not found"/>
    <div className={'container'}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
