import { ThemeProvider } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import { Header } from 'components/src/porady-ksiegowe.fakturuj.to/components/Layout/Header/Header';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import Image from '../Image/Image';
import { Main } from '../Main/Main';

type Props = { title: string; children: React.ReactNode; };

const calculateScrollPosition = (): number => {
  const scrollTop = window.pageYOffset;
  const winHeight = window.innerHeight;
  const docHeight = getDocHeight();

  const totalDocScrollLength = docHeight - winHeight;

  return Math.floor(scrollTop / totalDocScrollLength * 100);
};

const getDocHeight = () => {
  return Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight,
  );
};

const LayoutContainer: React.FC<Props> = ({ title, children }) => {
  const [progressBarPercent, setProgressBarPercent] = React.useState<number>(40);

  React.useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        const scrollPosition = calculateScrollPosition();
        setProgressBarPercent(scrollPosition);
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { colors: { secondary15, secondary16 } } = React.useContext(ThemeContext);

  return (
    <>
      <Header
        progressBar={{
          percent: progressBarPercent,
          strokeColor: secondary15,
          trailColor: secondary16,
        }}
        label={title}
      />
      <Main>
        <div>
          {children}
        </div>
        <Image/>
      </Main>
    </>
  );
};

type LayoutProps = {
  children: React.ReactNode;
  title?: string;
};

const Layout: React.FC<LayoutProps> = ({ children, title }) => (
  <ThemeProvider
    render={() => (
      <LayoutContainer
        title={title}

      >
        {children}
      </LayoutContainer>
    )}
  />
);

export default Layout;
