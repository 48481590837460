import * as React from 'react';
import styled from 'styled-components';
import * as classnames from 'classnames';

const MainComponent: React.FC<React.HTMLProps<HTMLElement>> = ({
                                                                 children,
                                                                 className,
                                                               }) => {
  const tailwindClassNames = [
    'ml-10',
    'mobile:ml-20',
    'laptop:ml-56',
    'laptop:flex',
    'laptop:flex-row',
  ];
  return (
    <main className={classnames(tailwindClassNames, className)}>
      {children}
    </main>
  );
};

export const Main = styled(MainComponent)`
  @media ${props => props.theme.deviceBreakpoints.laptop} {
    margin-left: 14rem;
  }
  @media ${props => props.theme.deviceBreakpoints.desktop} {
    margin-left: 28rem;
  }
`;
