import * as React from 'react';

type Props = {
  className?: string;
};
type ImageSharpFixed = {
  aspectRatio: number;
  base64: string;
  height: number;
  originalName: string;
  src: string;
  srcSet: string;
  srcSetWebp: string;
  srcWebp: string;
  tracedSVG: string;
  width: number;
};

type DeviceType =
  | 'small'
  | 'mobile'
  | 'tablet'
  | 'laptop'
  | 'desktop';

type ImagesQueryResult = Record<DeviceType, { childImageSharp: { fixed: ImageSharpFixed } }>;

const Image: React.FC<Props> = ({ className }) => {

  return (
    <div>
    </div>
  );
};
export default Image;
